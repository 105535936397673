.row-1 {
  background-image: url("../assets/images/image7.JPEG");
  height: 500px;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eb8864;
  /* background: linear-gradient(#d1958086, #d1958086),
    url("../assets/images/image7.JPEG"); */
}

.heading {
  color: white;
  filter: drop-shadow(5px 5px 15px #000000);
}
.shadow {
  filter: drop-shadow(5px 5px 15px #000000);
}

.b-p-card {
  height: 230px;
}

.puppies-col {
  background-image: url("../assets/images/image14.JPEG");
  height: 500px;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eb8864;
  padding: 0px;
  padding-left: 0.1px;
}
.our-dogs-col {
  background-image: url("../assets/images/image17.JPEG");
  height: 500px;
  background-position: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #eb8864;
  padding: 0px;
  padding-right: 0.1px;
}

.overlay {
  width: 100%;
  height: 100%;
  background-color: #eab09bcc;
  transition: 0.9s;
}
.overlay:hover {
  cursor: pointer;
  transition-timing-function: ease-in-out;
  transition: 0.9s;
  background-color: #eed9d14e;
}

.av-img {
  height: 300px;
}

.image {
  position: relative;
  z-index: 0;
  width: fit-content;
}
.rehomed {
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 1;
  background-color: #38b44a;
  border-radius: 5px 0px 0px 5px;
}

@keyframes blink {
  0% {
    background-color: #38b44a;
  }
  50% {
    background-color: #40c955;
  }
  100% {
    background-color: #38b44a;
  }
}
.just-rehomed {
  position: absolute;
  right: 25%;
  top: 10px;
  z-index: 1;
  background-color: #38b44a;
  animation: blink 1s infinite;
  border-radius: 5px;
}

footer {
  height: 50px;
}

.link-hover {
  color: white;
}
.link-hover:hover {
  color: rgb(233, 233, 233);
}

@media only screen and (max-width: 1136px) {
  .b-p-card {
    height: 275px;
  }
}

@media only screen and (max-width: 992px) {
  .b-p-card {
    height: fit-content;
  }
}
